<template>
  <div class="path-courses__wrapper">
    <div class="path-courses pt-4">
      <h3 class="path-results__title mb-4">Kapitoly</h3>
      <a
        v-for="course of path.attributes.courses.data"
        :key="course.id"
        :href="`/kurz/${course.attributes.slug}?seria=${path.attributes.slug}`"
        @click.prevent="onCourseClick(course, path.attributes.slug)"
        class="path-courses__course d-flex flex-row align-items-center pl-2 rounded-lg"
        :class="{ completed: isCourseCompleted(course) }"
      >
        <div
          class="path-courses__course__image-container mr-4"
          :class="{ completed: isCourseCompleted(course) }"
        >
          <b-img
            class="path-courses__course__image"
            :src="getCourseThumbnailUrl(course)"
            alt="Thumbnail"
            fluid
          ></b-img>
          <svg-checked-circle-icon
            v-if="isCourseCompleted(course)"
            class="image__completed-icon"
          />
        </div>
        <p class="m-0 font-weight-bold">
          <svg-open-lock-icon
            v-if="!isLoggedIn && course.attributes.public"
            class="is-public-icon w-color-dark-green"
          />
          <svg-closed-lock-icon
            v-if="!isLoggedIn && !course.attributes.public"
            class="is-public-icon w-color-dark-red"
          />
          {{ course.attributes.name }}
        </p>
      </a>
      <div
        class="path-courses__course d-flex flex-row align-items-center justify-content-center pl-2 pr-2 rounded-lg"
        :class="{ issued: isPathCompleted }"
      >
        <div
          class="path-courses__course__content_container d-flex flex-row mb-3 mb-md-0"
        >
          <div class="path-courses__course__image-container mr-4">
            <svg-trophy-icon
              class="icon-trophy"
              :class="{ issued: isPathCompleted }"
            />
          </div>
          <p class="path-courses__course__text-container m-0 font-weight-bold">
            {{ certificateText }}
          </p>
        </div>
        <b-button
          v-if="isPathCompleted"
          class="download-btn inline-block"
          @click="downloadPathCertificate"
        >
          Stiahnuť diplom
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { get } from "lodash";
import { downloadCertificate } from "/utils/downloadCertificate";
import { getCourseThumbnailUrl } from "/utils/helpers";

import * as Sentry from "@sentry/vue";

export default {
  components: {
    "svg-checked-circle-icon": () =>
      import("/assets/icons/checkedCircle.svg?inline"),
    "svg-open-lock-icon": () => import("/assets/icons/openLock.svg?inline"),
    "svg-closed-lock-icon": () => import("/assets/icons/closedLock.svg?inline"),
    "svg-trophy-icon": () => import("/assets/icons/trophy.svg?inline"),
  },

  props: {
    path: { required: true },
  },

  mounted() {
    this._loadUser();
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
    certificateText() {
      if (this.isPathCompleted) {
        return this.isAdmin
          ? "Gratulujeme, získali ste diplom!"
          : "Gratulujeme, získal/a si diplom!";
      }

      return this.isAdmin
        ? "Po vyplnení všetkých kvízov získavate diplom"
        : "Po vyplnení všetkých kvízov získavaš diplom";
    },
    isPathCompleted() {
      if (!this.isLoggedIn) {
        return false;
      }

      return this.user.pathCertificates.some(
        (certificate) => certificate.path.id === this.path.id
      );
    },
    pathCertificate() {
      if (!this.isPathCompleted) {
        return null;
      }

      return this.user.pathCertificates.find(
        (certificate) => certificate.path.id === this.path.id
      );
    },
  },

  methods: {
    onCourseClick(course, pathSlug) {
      const courseLink = `/kurz/${course.attributes.slug}?seria=${pathSlug}`;

      if (!this.isLoggedIn && !get(course, "attributes.public")) {
        localStorage.setItem("LAST_FROM_ROUTE", courseLink);
        this.EventBus.$emit("locked-content-accessed");
        return;
      }

      this.$router.push(courseLink);
    },
    downloadPathCertificate() {
      const certificate = { ...this.pathCertificate, user: { ...this.user } };
      downloadCertificate(certificate);
    },
    isCourseCompleted(course) {
      if (!this.isLoggedIn) {
        return false;
      }

      return this.user.courseCertificates.some(
        (certificate) => certificate.course.id === course.id
      );
    },
    async _loadUser() {
      try {
        await this.$store.dispatch("auth/user");
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    },
    getCourseThumbnailUrl,
  },
};
</script>

<style lang="scss" scoped>
.path-courses__wrapper {
  margin-right: 1.3125rem;
  border-image: linear-gradient(90.01deg, #0064ff 60.75%, #fb2564 79.89%) 1;
  border-bottom-width: 5px;
  border-bottom-style: solid;

  .path-courses {
    padding-left: 2.125rem;
    padding-right: 2.0625rem;
    padding-bottom: 3.4375rem;
    border: 1px solid var(--a-color-blue-border);
    border-bottom-width: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .path-courses__course {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--a-color-blue-border);

      &.completed {
        background-color: rgba(144, 238, 144, 0.2);
      }

      &.issued {
        background-color: #eb1b69;
      }

      &:last-child {
        flex-wrap: wrap;
        border-bottom: none;
      }

      .path-courses__course__content_container {
        flex-grow: 1;
      }

      .path-courses__course__image-container {
        position: relative;
        flex: 0 0 4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.completed {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: lightgreen;
            opacity: 0.9;
            z-index: 1;
          }
        }

        .image__completed-icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 25px;
          color: w-color-black;
          z-index: 2;
        }

        .path-courses__course__image {
          height: auto;
          border-radius: 3px;
        }
      }

      .path-courses__course__text-container {
        flex-grow: 1;
      }
    }

    a:hover {
      text-decoration: none;
      background-color: var(--a-color-blue-light);
      cursor: pointer;
    }

    a:active {
      background-color: var(--a-color-blue-shadow);
    }
  }
}

.is-public-icon {
  width: 1.5rem;
  margin-right: 0.25rem;
}

.icon-trophy {
  color: var(--a-color-secondary);
  width: 2rem;
  height: 2rem;

  &.issued {
    color: var(--a-color-yellow);
  }
}

// TODO: Fix button colors
::v-deep .download-btn {
  width: 136px;
  background-color: #2b2e4a;
  color: white !important;
  border-radius: 100px;
  border-color: #2b2e4a !important;
  font-size: small;
  font-weight: bold;
  min-width: 136px;
  min-height: 39px;
  padding: 0;

  &:hover {
    background-color: #ffffff;
    color: #2b2e4a !important;
    border-color: #ffffff !important;
  }
}
</style>
